import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  preferredTheme: localStorage.getItem('preferredTheme') || 'light', // Load from localStorage or default to 'light'
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setPreferredTheme(state, action) {
      state.preferredTheme = action.payload;
      localStorage.setItem('preferredTheme', action.payload); // Save to localStorage
    },
  },
});

export const { setPreferredTheme } = themeSlice.actions;

export default themeSlice.reducer;
