import { createSlice } from '@reduxjs/toolkit';

// Retrieve user info from localStorage
const getUserInfoFromLocalStorage = () => {
  try {
    const userInfo = localStorage.getItem('userInfo');
    return userInfo ? JSON.parse(userInfo) : null;
  } catch (error) {
    console.error('Error parsing userInfo from localStorage:', error);
    return null; // Return null if parsing fails
  }
};

// Retrieve preferred language from localStorage
const getPreferredLanguageFromLocalStorage = () => {
  try {
    return localStorage.getItem('preferredLanguage') || 'en'; // Default to English
  } catch (error) {
    console.error('Error retrieving preferredLanguage from localStorage:', error);
    return 'en'; // Fallback to English
  }
};

const initialState = {
  userInfo: getUserInfoFromLocalStorage(),
  preferredLanguage: getPreferredLanguageFromLocalStorage(),
  token: getUserInfoFromLocalStorage()?.token || null, // Add token to the state
  accountType: getUserInfoFromLocalStorage()?.accountType || 'free', // Default to free
  subscriptionStartDate: getUserInfoFromLocalStorage()?.subscriptionStartDate || null,
  subscriptionEndDate: getUserInfoFromLocalStorage()?.subscriptionEndDate || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // Set user credentials and sync to localStorage
    setCredentials: (state, action) => {
      const userInfo = action.payload;

      // Update Redux state
      state.userInfo = {
        ...userInfo,
        subscriptionStartDate: userInfo.subscriptionStartDate || null,
        subscriptionEndDate: userInfo.subscriptionEndDate || null,
        accountType: userInfo.accountType || 'free',
      };
      state.token = userInfo.token; // Store the token
      state.preferredLanguage = userInfo?.preferredLanguage || 'en';
      state.subscriptionStartDate = userInfo.subscriptionStartDate || null;
      state.subscriptionEndDate = userInfo.subscriptionEndDate || null;
      state.accountType = userInfo.accountType || 'free';

      // Sync to localStorage
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
      localStorage.setItem('preferredLanguage', userInfo?.preferredLanguage || 'en');
    },

    // Logout the user and clear localStorage
    logout: (state) => {
      state.userInfo = null;
      state.token = null; // Clear the token
      state.preferredLanguage = 'en'; // Reset language to default
      state.accountType = 'free'; // Reset to default account type
      state.subscriptionStartDate = null;
      state.subscriptionEndDate = null;

      // Clear from localStorage
      localStorage.removeItem('userInfo');
      localStorage.removeItem('preferredLanguage');
    },

    // Set preferred language and sync with localStorage
    setPreferredLanguage: (state, action) => {
      const preferredLanguage = action.payload;

      // Update Redux state
      state.preferredLanguage = preferredLanguage;

      // Sync to localStorage
      localStorage.setItem('preferredLanguage', preferredLanguage);

      // Update userInfo's preferredLanguage if userInfo exists
      if (state.userInfo) {
        state.userInfo.preferredLanguage = preferredLanguage;
        localStorage.setItem('userInfo', JSON.stringify(state.userInfo)); // Sync userInfo
      }
    },

    // Update subscription details in userInfo and central state
    updateSubscriptionDetails: (state, action) => {
      const {
        subscriptionStartDate = null,
        subscriptionEndDate = null,
        accountType = state.accountType || 'free',
      } = action.payload;

      // Update Redux state
      state.subscriptionStartDate = subscriptionStartDate;
      state.subscriptionEndDate = subscriptionEndDate;
      state.accountType = accountType;

      if (state.userInfo) {
        // Update userInfo in Redux state
        state.userInfo.subscriptionStartDate = subscriptionStartDate;
        state.userInfo.subscriptionEndDate = subscriptionEndDate;
        state.userInfo.accountType = accountType;

        // Sync to localStorage
        localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
      }
    },

    // Handle premium plans specifically
    updatePremiumPlan: (state, action) => {
      const { accountType } = action.payload;

      // Update Redux state
      state.accountType = accountType;

      if (state.userInfo) {
        // Update the account type for premium plans
        state.userInfo.accountType = accountType;

        // Sync to localStorage
        localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
      }
    },
  },
});

// Export the actions
export const {
  setCredentials,
  logout,
  setPreferredLanguage,
  updateSubscriptionDetails,
  updatePremiumPlan,
} = authSlice.actions;

// Export the reducer
export default authSlice.reducer;
