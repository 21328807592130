import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  preferredLanguage: localStorage.getItem('preferredLanguage') || 'en', // Load from localStorage or default to 'en'
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setPreferredLanguage(state, action) {
      state.preferredLanguage = action.payload;
      localStorage.setItem('preferredLanguage', action.payload); // Save to localStorage
    },
  },
});

export const { setPreferredLanguage } = languageSlice.actions;

export default languageSlice.reducer;
