import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css'; // Global styles
import { Provider } from 'react-redux'; // Import Provider for Redux
import store from './store'; // Import your Redux store
import App from './App'; // Main App component
import reportWebVitals from './reportWebVitals'; // Optional performance measurement

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    {/* Wrap the App component with Provider and pass in the Redux store */}
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// Optional performance monitoring
reportWebVitals();
