const sideMenu = {
  en: {
    upload: "Upload",
    profile: "Profile",
    payments: "Pricing",
    home: "Home",
    backlog: "Charts", // Updated
    charts: "Reports", // Updated
    settings: "Settings",
    support: "Support",
  },
  ru: {
    upload: "Загрузить",
    profile: "Профиль",
    payments: "Цены",
    home: "Главная",
    backlog: "Графики", // New translation
    charts: "Отчёты",
    settings: "Настройки",
    support: "Поддержка",
  },
  pt: {
    upload: "Enviar",
    profile: "Perfil",
    payments: "Preços",
    home: "Início",
    backlog: "Gráficos", // New translation
    charts: "Relatórios",
    settings: "Configurações",
    support: "Suporte",
  },
  it: {
    upload: "Carica",
    profile: "Profilo",
    payments: "Prezzi",
    home: "Home",
    backlog: "Grafici", // New translation
    charts: "Rapporti",
    settings: "Impostazioni",
    support: "Supporto",
  },
  es: {
    upload: "Subir",
    profile: "Perfil",
    payments: "Precios",
    home: "Inicio",
    backlog: "Gráficos", // New translation
    charts: "Informes",
    settings: "Configuración",
    support: "Soporte",
  },
  de: {
    upload: "Hochladen",
    profile: "Profil",
    payments: "Preise",
    home: "Startseite",
    backlog: "Diagramme", // New translation
    charts: "Berichte",
    settings: "Einstellungen",
    support: "Unterstützung",
  },
  fr: {
    upload: "Télécharger",
    profile: "Profil",
    payments: "Tarifs",
    home: "Accueil",
    backlog: "Graphiques", // New translation
    charts: "Rapports",
    settings: "Paramètres",
    support: "Support",
  },
  pl: {
    upload: "Prześlij",
    profile: "Profil",
    payments: "Cennik",
    home: "Strona główna",
    backlog: "Wykresy", // New translation
    charts: "Raporty",
    settings: "Ustawienia",
    support: "Wsparcie",
  },
  tr: {
    upload: "Yükle",
    profile: "Profil",
    payments: "Fiyatlandırma",
    home: "Anasayfa",
    backlog: "Grafikler", // New translation
    charts: "Raporlar",
    settings: "Ayarlar",
    support: "Destek",
  },
  ja: {
    upload: "アップロード",
    profile: "プロフィール",
    payments: "価格",
    home: "ホーム",
    backlog: "チャート", // New translation
    charts: "レポート",
    settings: "設定",
    support: "サポート",
  },
  ko: {
    upload: "업로드",
    profile: "프로필",
    payments: "가격",
    home: "홈",
    backlog: "차트", // New translation
    charts: "보고서",
    settings: "설정",
    support: "지원",
  },
  "zh-CN": {
    upload: "上传",
    profile: "个人资料",
    payments: "定价",
    home: "首页",
    backlog: "图表", // New translation
    charts: "报告",
    settings: "设置",
    support: "支持",
  },
  "zh-TW": {
    upload: "上傳",
    profile: "個人資料",
    payments: "定價",
    home: "首頁",
    backlog: "圖表", // New translation
    charts: "報告",
    settings: "設置",
    support: "支持",
  },
  vi: {
    upload: "Tải lên",
    profile: "Hồ sơ",
    payments: "Giá cả",
    home: "Trang chủ",
    backlog: "Biểu đồ", // New translation
    charts: "Báo cáo",
    settings: "Cài đặt",
    support: "Hỗ trợ",
  },
  th: {
    upload: "อัปโหลด",
    profile: "โปรไฟล์",
    payments: "การกำหนดราคา",
    home: "หน้าหลัก",
    backlog: "แผนภูมิ", // New translation
    charts: "รายงาน",
    settings: "การตั้งค่า",
    support: "สนับสนุน",
  },
  id: {
    upload: "Unggah",
    profile: "Profil",
    payments: "Harga",
    home: "Beranda",
    backlog: "Bagan", // New translation
    charts: "Laporan",
    settings: "Pengaturan",
    support: "Dukungan",
  },
};

export default sideMenu;
