import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import sideMenu from '../locales/sideMenu';
import notFound from '../locales/notFound';
import homePage from '../locales/homePage'; // Add the homePage translations

const resources = {
  en: {
    translation: {
      ...sideMenu.en,
      ...notFound.en,
      ...homePage.en, // Merge homePage translations
    },
  },
  ru: {
    translation: {
      ...sideMenu.ru,
      ...notFound.ru,
      ...homePage.ru,
    },
  },
  pt: {
    translation: {
      ...sideMenu.pt,
      ...notFound.pt,
      ...homePage.pt,
    },
  },
  it: {
    translation: {
      ...sideMenu.it,
      ...notFound.it,
      ...homePage.it,
    },
  },
  es: {
    translation: {
      ...sideMenu.es,
      ...notFound.es,
      ...homePage.es,
    },
  },
  de: {
    translation: {
      ...sideMenu.de,
      ...notFound.de,
      ...homePage.de,
    },
  },
  fr: {
    translation: {
      ...sideMenu.fr,
      ...notFound.fr,
      ...homePage.fr,
    },
  },
  pl: {
    translation: {
      ...sideMenu.pl,
      ...notFound.pl,
      ...homePage.pl,
    },
  },
  tr: {
    translation: {
      ...sideMenu.tr,
      ...notFound.tr,
      ...homePage.tr,
    },
  },
  ja: {
    translation: {
      ...sideMenu.ja,
      ...notFound.ja,
      ...homePage.ja,
    },
  },
  ko: {
    translation: {
      ...sideMenu.ko,
      ...notFound.ko,
      ...homePage.ko,
    },
  },
  "zh-CN": {
    translation: {
      ...sideMenu["zh-CN"],
      ...notFound["zh-CN"],
      ...homePage["zh-CN"],
    },
  },
  "zh-TW": {
    translation: {
      ...sideMenu["zh-TW"],
      ...notFound["zh-TW"],
      ...homePage["zh-TW"],
    },
  },
  vi: {
    translation: {
      ...sideMenu.vi,
      ...notFound.vi,
      ...homePage.vi,
    },
  },
  th: {
    translation: {
      ...sideMenu.th,
      ...notFound.th,
      ...homePage.th,
    },
  },
  id: {
    translation: {
      ...sideMenu.id,
      ...notFound.id,
      ...homePage.id,
    },
  },
};

i18n
  .use(LanguageDetector) // Detects the user's language
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
