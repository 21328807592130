const notFound = {
  en: {
    notFoundTitle: "Oops, you found a hidden gem! 🎉",
    notFoundMessage: "It seems like you've reached a page that doesn't exist. No worries, we're here to guide you back!",
    notFoundSubMessage: "Click the button below to return to the main page and continue exploring our amazing tools!",
    notFoundButton: "Back to Home",
  },
  ru: {
    notFoundTitle: "Ой, вы нашли скрытую жемчужину! 🎉",
    notFoundMessage: "Похоже, вы попали на несуществующую страницу. Не переживайте, мы поможем вам вернуться!",
    notFoundSubMessage: "Нажмите на кнопку ниже, чтобы вернуться на главную страницу и продолжить исследовать наши потрясающие инструменты!",
    notFoundButton: "Вернуться на главную",
  },
  pt: {
    notFoundTitle: "Ops, você encontrou uma joia escondida! 🎉",
    notFoundMessage: "Parece que você chegou a uma página que não existe. Não se preocupe, estamos aqui para guiá-lo de volta!",
    notFoundSubMessage: "Clique no botão abaixo para retornar à página principal e continuar explorando nossas ferramentas incríveis!",
    notFoundButton: "Voltar ao início",
  },
  it: {
    notFoundTitle: "Ops, hai trovato un gioiello nascosto! 🎉",
    notFoundMessage: "Sembra che tu abbia raggiunto una pagina che non esiste. Non preoccuparti, siamo qui per guidarti indietro!",
    notFoundSubMessage: "Clicca il pulsante qui sotto per tornare alla pagina principale e continuare ad esplorare i nostri fantastici strumenti!",
    notFoundButton: "Torna alla home",
  },
  es: {
    notFoundTitle: "¡Ups, encontraste una joya oculta! 🎉",
    notFoundMessage: "Parece que has llegado a una página que no existe. ¡No te preocupes, estamos aquí para guiarte de regreso!",
    notFoundSubMessage: "Haz clic en el botón de abajo para regresar a la página principal y seguir explorando nuestras increíbles herramientas.",
    notFoundButton: "Volver a inicio",
  },
  de: {
    notFoundTitle: "Ups, du hast einen versteckten Schatz gefunden! 🎉",
    notFoundMessage: "Es scheint, als ob du eine Seite erreicht hast, die nicht existiert. Keine Sorge, wir helfen dir, zurückzukehren!",
    notFoundSubMessage: "Klicke auf die Schaltfläche unten, um zur Hauptseite zurückzukehren und unsere fantastischen Tools weiter zu erkunden!",
    notFoundButton: "Zurück zur Startseite",
  },
  fr: {
    notFoundTitle: "Oups, vous avez trouvé un trésor caché ! 🎉",
    notFoundMessage: "Il semble que vous soyez arrivé sur une page qui n'existe pas. Ne vous inquiétez pas, nous sommes là pour vous guider !",
    notFoundSubMessage: "Cliquez sur le bouton ci-dessous pour revenir à la page principale et continuer à explorer nos outils incroyables !",
    notFoundButton: "Retour à l'accueil",
  },
  pl: {
    notFoundTitle: "Ups, znalazłeś ukryty skarb! 🎉",
    notFoundMessage: "Wygląda na to, że trafiłeś na stronę, która nie istnieje. Nie martw się, pomożemy ci wrócić!",
    notFoundSubMessage: "Kliknij przycisk poniżej, aby wrócić na stronę główną i kontynuować eksplorację naszych niesamowitych narzędzi!",
    notFoundButton: "Powrót do strony głównej",
  },
  tr: {
    notFoundTitle: "Hop, gizli bir hazine buldunuz! 🎉",
    notFoundMessage: "Görünüşe göre mevcut olmayan bir sayfaya ulaştınız. Endişelenmeyin, sizi geri yönlendireceğiz!",
    notFoundSubMessage: "Ana sayfaya dönmek ve harika araçlarımızı keşfetmeye devam etmek için aşağıdaki düğmeye tıklayın!",
    notFoundButton: "Ana Sayfaya Dön",
  },
  ja: {
    notFoundTitle: "おっと、隠された宝物を見つけました！ 🎉",
    notFoundMessage: "存在しないページにアクセスしたようです。心配しないで、戻る方法をお手伝いします！",
    notFoundSubMessage: "メインページに戻って素晴らしいツールを引き続き探索するには、下のボタンをクリックしてください！",
    notFoundButton: "ホームに戻る",
  },
  ko: {
    notFoundTitle: "앗, 숨겨진 보물을 발견하셨군요! 🎉",
    notFoundMessage: "존재하지 않는 페이지에 도달한 것 같습니다. 걱정하지 마세요, 다시 돌아가는 길을 안내해 드리겠습니다!",
    notFoundSubMessage: "메인 페이지로 돌아가서 우리의 멋진 도구를 계속 탐색하려면 아래 버튼을 클릭하세요!",
    notFoundButton: "홈으로 돌아가기",
  },
  "zh-CN": {
    notFoundTitle: "哎呀，您发现了一个隐藏的宝藏！ 🎉",
    notFoundMessage: "看起来您访问了一个不存在的页面。别担心，我们会引导您返回！",
    notFoundSubMessage: "点击下面的按钮返回主页，继续探索我们的精彩工具！",
    notFoundButton: "返回主页",
  },
  "zh-TW": {
    notFoundTitle: "哎呀，您發現了一個隱藏的寶藏！ 🎉",
    notFoundMessage: "看起來您訪問了一個不存在的頁面。別擔心，我們會引導您返回！",
    notFoundSubMessage: "點擊下面的按鈕返回主頁，繼續探索我們的精彩工具！",
    notFoundButton: "返回主頁",
  },
  vi: {
    notFoundTitle: "Rất tiếc, bạn đã tìm thấy một viên ngọc ẩn! 🎉",
    notFoundMessage: "Có vẻ như bạn đã đến một trang không tồn tại. Đừng lo lắng, chúng tôi sẽ hướng dẫn bạn quay lại!",
    notFoundSubMessage: "Nhấp vào nút bên dưới để quay lại trang chính và tiếp tục khám phá các công cụ tuyệt vời của chúng tôi!",
    notFoundButton: "Quay lại trang chủ",
  },
  th: {
    notFoundTitle: "โอ๊ะ คุณพบสมบัติที่ซ่อนอยู่! 🎉",
    notFoundMessage: "ดูเหมือนว่าคุณจะไปยังหน้าที่ไม่มีอยู่ ไม่ต้องกังวล เราจะช่วยคุณกลับไป!",
    notFoundSubMessage: "คลิกปุ่มด้านล่างเพื่อกลับไปยังหน้าหลักและสำรวจเครื่องมืออันยอดเยี่ยมของเราต่อไป!",
    notFoundButton: "กลับไปหน้าหลัก",
  },
  id: {
    notFoundTitle: "Ups, Anda menemukan permata tersembunyi! 🎉",
    notFoundMessage: "Sepertinya Anda telah mencapai halaman yang tidak ada. Jangan khawatir, kami akan membantu Anda kembali!",
    notFoundSubMessage: "Klik tombol di bawah untuk kembali ke halaman utama dan melanjutkan menjelajahi alat-alat luar biasa kami!",
    notFoundButton: "Kembali ke Beranda",
  },
};

export default notFound;
