import React, { useState, useEffect } from 'react';
import { faCloudArrowUp, faChartPie, faCreditCard, faSquarePollHorizontal, faLifeRing } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MenuItem from './MenuItem';

const SideMenu = () => {
  const { t } = useTranslation();
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const { userInfo } = useSelector((state) => state.auth); // Get user info from Redux

  // Toggle menu visibility based on screen size
  useEffect(() => {
    const handleResize = () => {
      setIsMenuVisible(window.innerWidth >= 640);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => window.removeEventListener('resize', handleResize); // Cleanup listener on unmount
  }, []);

  return (
    <div className={`flex flex-col min-h-screen ${isMenuVisible ? 'block' : 'hidden'} sm:block`}>
      <nav className="bg-gradient-indigo-purple text-white flex flex-col flex-grow p-4 shadow-effect h-full w-16 sm:w-48 md:w-56 lg:w-64">
        <ul className="space-y-4 flex-grow">
          <MenuItem to="/" icon={faCloudArrowUp} label={t('upload')} />
          {userInfo && ( // Only show backlog menu if user is authorized
            <MenuItem to="/charts" icon={faChartPie} label={t('backlog')} />
          )}
          {userInfo && ( // Only show dashboard if user is authorized
            <MenuItem to="/dashboard" icon={faSquarePollHorizontal} label={t('charts')} />
          )}
          {!userInfo && (
            <MenuItem to="/pricing" icon={faCreditCard} label={t('payments')} />
          )}
          {userInfo && (
            <MenuItem to="/support" icon={faLifeRing} label={t('support')} />
          )}
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;
