import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const countriesApiSlice = createApi({
  reducerPath: 'countriesApi', // Unique key for this slice
  baseQuery: fetchBaseQuery({ baseUrl: 'https://restcountries.com/v3.1' }), // Base URL for API
  endpoints: (builder) => ({
    fetchCountries: builder.query({
      query: () => '/all', // Endpoint to fetch all countries
      transformResponse: (response) =>
        response.map((c) => c.name.common).sort(), // Transform response to get country names
    }),
  }),
});

export const { useFetchCountriesQuery } = countriesApiSlice;
