import { configureStore } from '@reduxjs/toolkit';
import { usersApiSlice } from './slices/usersApiSlice';
import { countriesApiSlice } from './slices/countriesApiSlice';
import authSlice from './slices/authSlice';
import languageReducer from './slices/languageSlice';
import themeReducer from './slices/themeSlice';

const store = configureStore({
    reducer: {
        [usersApiSlice.reducerPath]: usersApiSlice.reducer, // Handles API interactions for users
        [countriesApiSlice.reducerPath]: countriesApiSlice.reducer, // Handles API interactions for countries
        auth: authSlice, // Manages authentication state
        language: languageReducer, // Manages language preferences
        theme: themeReducer, // Manages theme preferences (dark/light mode)
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            usersApiSlice.middleware,
            countriesApiSlice.middleware // Add countries API middleware
        ),
});

export default store;
