import React, { useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ResetPasswordPage = () => {
    const { token } = useParams(); // Retrieve token from the URL
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [apiMessage, setApiMessage] = useState(''); // State for showing backend message

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);

    const handleResetPassword = async (event) => {
        event.preventDefault();

        // Input validation
        if (!password || !confirmPassword) {
            toast.error('Both password fields are required.');
            setApiMessage('Both password fields are required.');
            return;
        }

        if (password.length < 8) {
            toast.error('Password must be at least 8 characters long.');
            setApiMessage('Password must be at least 8 characters long.');
            return;
        }

        if (password !== confirmPassword) {
            toast.error('Passwords do not match.');
            setApiMessage('Passwords do not match.');
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post(
                `https://api.monchart.com/api/auth/reset-password/${token}`,
                { password }
            );

            // Show the success message from the backend
            const successMessage = response.data.message || 'Password has been reset successfully.';
            setApiMessage(successMessage);
            toast.success(successMessage);

            // Redirect to login page after success
            navigate('/login');
        } catch (error) {
            // Log the error for debugging
            console.error('Error resetting password:', error);

            // Extract error message from backend or show generic message
            const errorMessage =
                error.response?.data?.message || 'An unexpected error occurred. Please try again.';
            setApiMessage(errorMessage);
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-7xl mx-auto px-6 pt-4 pb-8 lg:py-10 min-h-screen text-center">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-orange-700 mb-8">
                Reset Your Password
            </h1>

            <section className="flex flex-col items-center gap-8">
                <div className="flex flex-col gap-4 items-center justify-center p-6 border border-gray-300 rounded-md shadow-md bg-white w-full sm:w-80 md:w-[400px]">
                    {/* Display API Message */}
                    {apiMessage && (
                        <div className="text-green-600 text-sm font-medium text-center mb-4">
                            {apiMessage}
                        </div>
                    )}

                    <form onSubmit={handleResetPassword} className="flex flex-col w-full gap-6">
                        {/* Password Input */}
                        <div className="relative w-full max-w-md">
                            <label htmlFor="password" className="text-lg text-gray-900 mb-1">
                                New Password
                            </label>
                            <input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your new password"
                                required
                                className="w-full px-4 py-2 rounded-md border border-gray-300 bg-white text-lg text-gray-900 placeholder-gray-500 pr-10"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute right-3 top-2/3 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                        </div>

                        {/* Confirm Password Input */}
                        <div className="relative w-full max-w-md">
                            <label htmlFor="confirmPassword" className="text-lg text-gray-900 mb-1">
                                Confirm Password
                            </label>
                            <input
                                id="confirmPassword"
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm your new password"
                                required
                                className="w-full px-4 py-2 rounded-md border border-gray-300 bg-white text-lg text-gray-900 placeholder-gray-500 pr-10"
                            />
                            <button
                                type="button"
                                onClick={toggleConfirmPasswordVisibility}
                                className="absolute right-3 top-2/3 transform -translate-y-1/2 text-gray-600 hover:text-gray-800"
                            >
                                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                            </button>
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            disabled={loading}
                            className={`bg-yellow-500 text-white font-bold py-2 px-6 rounded-md shadow-md ${
                                loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-600'
                            }`}
                        >
                            {loading ? 'Resetting...' : 'Reset Password'}
                        </button>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default ResetPasswordPage;
