const homePage = {
  en: {
    title: "MonChart",
    subtitle1: "Online Converter CSV, Excel, XML, JSON, YAML, HTML files",
    subtitle2: "To Visual Charts and Analytical Reports",
    loading: "Loading additional content...",
  },
  ru: {
    title: "MonChart",
    subtitle1: "Онлайн-конвертер CSV, Excel, XML, JSON, YAML, HTML файлов",
    subtitle2: "В визуальные графики и аналитические отчёты",
    loading: "Загрузка дополнительного содержимого...",
  },
  pt: {
    title: "MonChart",
    subtitle1: "Conversor online de arquivos CSV, Excel, XML, JSON, YAML, HTML",
    subtitle2: "Para gráficos visuais e relatórios analíticos",
    loading: "Carregando conteúdo adicional...",
  },
  it: {
    title: "MonChart",
    subtitle1: "Convertitore online di file CSV, Excel, XML, JSON, YAML, HTML",
    subtitle2: "In grafici visivi e rapporti analitici",
    loading: "Caricamento di contenuti aggiuntivi...",
  },
  es: {
    title: "MonChart",
    subtitle1: "Convertidor en línea de archivos CSV, Excel, XML, JSON, YAML, HTML",
    subtitle2: "A gráficos visuales e informes analíticos",
    loading: "Cargando contenido adicional...",
  },
  de: {
    title: "MonChart",
    subtitle1: "Online-Konverter für CSV-, Excel-, XML-, JSON-, YAML-, HTML-Dateien",
    subtitle2: "Zu visuellen Diagrammen und analytischen Berichten",
    loading: "Zusätzlicher Inhalt wird geladen...",
  },
  fr: {
    title: "MonChart",
    subtitle1: "Convertisseur en ligne de fichiers CSV, Excel, XML, JSON, YAML, HTML",
    subtitle2: "Vers des graphiques visuels et rapports analytiques",
    loading: "Chargement de contenu supplémentaire...",
  },
  pl: {
    title: "MonChart",
    subtitle1: "Internetowy konwerter plików CSV, Excel, XML, JSON, YAML, HTML",
    subtitle2: "Do wykresów wizualnych i raportów analitycznych",
    loading: "Ładowanie dodatkowej zawartości...",
  },
  tr: {
    title: "MonChart",
    subtitle1: "Çevrimiçi CSV, Excel, XML, JSON, YAML, HTML dosya dönüştürücü",
    subtitle2: "Görsel grafiklere ve analitik raporlara dönüştürün",
    loading: "Ek içerik yükleniyor...",
  },
  ja: {
    title: "MonChart",
    subtitle1: "CSV、Excel、XML、JSON、YAML、HTMLファイルをオンラインで変換",
    subtitle2: "視覚的なグラフと分析レポートへ",
    loading: "追加コンテンツを読み込み中...",
  },
  ko: {
    title: "MonChart",
    subtitle1: "CSV, Excel, XML, JSON, YAML, HTML 파일 온라인 변환기",
    subtitle2: "시각적 차트 및 분석 보고서로",
    loading: "추가 콘텐츠 로드 중...",
  },
  "zh-CN": {
    title: "MonChart",
    subtitle1: "在线转换 CSV、Excel、XML、JSON、YAML、HTML 文件",
    subtitle2: "为可视化图表和分析报告",
    loading: "加载更多内容...",
  },
  "zh-TW": {
    title: "MonChart",
    subtitle1: "線上轉換 CSV、Excel、XML、JSON、YAML、HTML 檔案",
    subtitle2: "為視覺化圖表和分析報告",
    loading: "載入更多內容...",
  },
  vi: {
    title: "MonChart",
    subtitle1: "Trình chuyển đổi trực tuyến tệp CSV, Excel, XML, JSON, YAML, HTML",
    subtitle2: "Thành biểu đồ trực quan và báo cáo phân tích",
    loading: "Đang tải thêm nội dung...",
  },
  th: {
    title: "MonChart",
    subtitle1: "ตัวแปลงไฟล์ CSV, Excel, XML, JSON, YAML, HTML ออนไลน์",
    subtitle2: "เป็นกราฟภาพและรายงานเชิงวิเคราะห์",
    loading: "กำลังโหลดเนื้อหาเพิ่มเติม...",
  },
  id: {
    title: "MonChart",
    subtitle1: "Pengonversi online file CSV, Excel, XML, JSON, YAML, HTML",
    subtitle2: "Ke grafik visual dan laporan analitis",
    loading: "Memuat konten tambahan...",
  },
};

export default homePage;
