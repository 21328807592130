import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Dynamically set the base URL based on the environment
const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://api.monchart.com'
  : 'http://localhost:3001';

const VerifyPage = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [statusMessage, setStatusMessage] = useState('');

    useEffect(() => {
        const verifyEmail = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/auth/verify/${token}`, {
                    method: 'GET',
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error('Verification failed. Please try again later.');
                }

                const data = await response.json();
                setStatusMessage(data.message);
                toast.success(data.message);

                // Redirect to login after 5 seconds
                setTimeout(() => navigate('/login'), 5000);
            } catch (error) {
                const message =
                    error.message || 'Verification failed. Please try again later.';
                setStatusMessage(message);
                toast.error(message);

                // Redirect to Resend Verification Page after 3 seconds for specific error
                if (message.includes('Invalid or expired token')) {
                    setTimeout(() => navigate('/resend-verification'), 3000);
                }
            } finally {
                setLoading(false);
            }
        };

        verifyEmail();
    }, [token, navigate]);

    return (
        <div className="max-w-7xl mx-auto px-6 pt-4 pb-8 lg:py-10 min-h-screen text-center">
            {loading ? (
                <h1 className="text-2xl font-bold text-yellow-500">Verifying your email...</h1>
            ) : (
                <h1 className="text-2xl font-bold text-yellow-500">{statusMessage}</h1>
            )}
        </div>
    );
};

export default VerifyPage;
