import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const ResendVerificationPage = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleResendVerification = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('https://api.monchart.com/api/auth/resend-verification', { email });
            toast.success(response.data.message);
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to resend verification email.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-7xl mx-auto px-6 pt-4 pb-8 lg:py-10 min-h-screen text-center">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-orange-700 mb-8">
                Resend Verification Email
            </h1>
            <form onSubmit={handleResendVerification} className="flex flex-col items-center gap-4">
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="w-full max-w-md px-4 py-2 rounded-md border border-gray-300"
                />
                <button
                    type="submit"
                    disabled={loading}
                    className={`bg-yellow-500 text-white font-bold py-2 px-6 rounded-md shadow-md ${
                        loading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-600'
                    }`}
                >
                    {loading ? 'Resending...' : 'Resend Verification'}
                </button>
            </form>
        </div>
    );
};

export default ResendVerificationPage;
